import React, { FC } from 'react';
import styled from 'styled-components';

import howItWorks01 from '../../images/ecards/how-it-works/eCardsHIWStep1.svg';
import howItWorks02 from '../../images/ecards/how-it-works/eCardsHIWStep2.svg';
import howItWorks03 from '../../images/ecards/how-it-works/eCardsHIWStep3.svg';

import howItWorks04 from '../../images/ecards/how-it-works/eCardsHIWMobileStep1.svg';
import howItWorks05 from '../../images/ecards/how-it-works/eCardsHIWMobileStep2.svg';
import howItWorks06 from '../../images/ecards/how-it-works/eCardsHIWMobileStep3.svg';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 108px 24px 100px 24px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  justify-content-center;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 142px 56px 142px 56px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 193px 86px 180px 86px;
  }

`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.dirtOrange};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 8px 0;
  text-align: center;
  z-index: 1;
  margin-right: 0;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    margin: 0 auto 10px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 20px;
    margin: 0 auto 10px 0;
    text-align: left;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  padding: 0 0 16px 0;
  text-align: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    padding: 0 0 16px 16px;
    position: relative;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 24px 62px 24px;
  text-align: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 18px;
    line-height: 24px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    padding: 8px 0 0 0;
  }
`;

const CardHeading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  z-index: 1;
  margin: 0 0 8px 0;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 28px;
    line-height: 34px;
    text-align: left;
    margin: 0 auto 8px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
    line-height: 34px;
    position: relative;
    text-align: left;
    margin: 0 auto 8px 0;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 8px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    margin: 0 auto 8px 0;
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    margin: 0 auto 8px 0;
    text-align: left;
  }
`;

const Body = styled.div`
  display: flex;
  gap: 60px;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    gap: 46px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    gap: 66px;
  }
`;

const Section = styled.div`
  padding: 0 24px;
  position: relative;
  width: 100%;
  margin: 0 0 59px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 8px;
    margin: 0;
  }
`;

const HowCardsWorkImage = styled.img<{ lastOffset?: boolean }>`
  width: 100%;
  margin: ${(props) => (props.lastOffset ? '0 0 35px 0' : '0 0 24px 0')};
  display: none;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 100%;
    display: inline-block;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 100%;
    display: inline-block;
  }
`;

const HowCardsWorkImageMobile = styled.img`
  width: 100%;
  margin: 0 0 24px 0;
  display: inline-block;
  max-width: 320px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 100%;
    margin: 0 0 24px 0;
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 100%;
    margin: 0 0 41px 0;
    display: none;
  }
`;

const HowCardsWork: FC = () => {
  return (
    <Layout>
      <Heading>How eCards work</Heading>
      <SubTitle>Celebrating friends, colleagues and loved ones has never been easier.</SubTitle>
      <Body>
        <Section>
          <HowCardsWorkImage alt='Birthday ecard' src={howItWorks01} />
          <HowCardsWorkImageMobile alt='Birthday ecard' src={howItWorks04} />
          <Title>Step 1</Title>
          <CardHeading>Create a link</CardHeading>
          <Paragraph>
            Enter basic info for the group card, like who it’s for and a deadline and, ta-da! You’ve
            generated a link to share with friends. Yup, it’s that easy.
          </Paragraph>
        </Section>
        <Section>
          <HowCardsWorkImage alt='Birthday group card' src={howItWorks02} />
          <HowCardsWorkImageMobile alt='Birthday group card' src={howItWorks05} />
          <Title>Step 2</Title>
          <CardHeading>Add your notes</CardHeading>
          <Paragraph>
            Write private, personal messages only the recipient will see. Add your own personal
            flair with a theme, photos, videos, GIFs, and more.
          </Paragraph>
        </Section>
        <Section>
          <HowCardsWorkImage lastOffset={true} alt='Free group card' src={howItWorks03} />
          <HowCardsWorkImageMobile alt='Free group card' src={howItWorks06} />
          <Title>Step 3</Title>
          <CardHeading>Send the card</CardHeading>
          <Paragraph>
            When it’s time, we’ll notify you to enter the recipient’s email and send their card! We
            guarantee it will make their day.
          </Paragraph>
        </Section>
      </Body>
    </Layout>
  );
};

export default HowCardsWork;
