import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-gap: 7px;
  grid-template-columns: repeat(12, 1fr);
  width: 570px;
`;

const Image1 = styled.img`
  grid-column-start: 6;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 3;
  width: 100%;
`;

const Image2 = styled.img`
  grid-column-start: 2;
  padding-right: 12px;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 8;
  width: 100%; ;
`;

const Image3 = styled.img`
  grid-column-start: 6;
  grid-column-end: 12;
  grid-row-start: 4;
  grid-row-end: 8;
  width: 100%;
`;

const Image4 = styled.img`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 9;
  grid-row-end: 12;
  width: 100%;
`;

const Image5 = styled.img`
  width: 100%;
  margin-left: 14px;
  grid-column-start: 7;
  grid-column-end: 11;
  grid-row-start: 9;
  grid-row-end: 11;
`;

interface Props {
  images: { image: string; alt: string }[];
}
const ImageWall: FC<Props> = ({ images }) => {
  return (
    <Container>
      <Image1 alt={images[0].alt} src={images[0].image} />
      <Image2 alt={images[1].alt} src={images[1].image} />
      <Image3 alt={images[2].alt} src={images[2].image} />
      <Image4 alt={images[3].alt} src={images[3].image} />
      <Image5 alt={images[4].alt} src={images[4].image} />
    </Container>
  );
};

export default ImageWall;
