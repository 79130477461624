import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../components/MetaTags';
import { theme } from '../styles/theme';
import Background from '../components/Background';
import Header from '../components/Header2';
import MakeSomeonesOccasionTogether from '../sections/ecards/MakeSomeonesOccasionTogether';
import HowCardsWork from '../sections/ecards/HowCardsWork';
import ThemePack from '../sections/ecards/ThemePack';
import PrivateNotes from '../sections/ecards/PrivateNotes';
import MediaCollages from '../sections/ecards/MediaCollages';
import Previews from '../sections/ecards/Previews';
import Join from '../sections/Join';
import FooterNew from '../sections/common/FooterWithoutFormFactorHookSecondary';
import eCardsData from '../sections/ecards/data';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const Marketing: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='eCards | illume'
        description={
          "Pick from illume's customizable, free ecards for any occasion: birthday cards, holidays, anniversaries, thank-you cards, greeting cards, group gifts."
        }
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <MakeSomeonesOccasionTogether
          headingTexts={eCardsData.headingTexts}
          reviews={eCardsData.reviews}
        />
        <HowCardsWork />
        <ThemePack />
        <PrivateNotes gifts={eCardsData.gifts} />
        <MediaCollages />
        <Previews />
        <Join />
        <FooterNew />
      </Layout>
    </ThemeProvider>
  );
};

export default Marketing;
