import React, { FC } from 'react';
import styled from 'styled-components';

import ReviewsSlider from '../../components/eCardPreviewsSlider/eCardPreviewsSlider';
import LinkButton from '../../components/LinkButton';
import { CREATE_CARD_LINK } from '../../constants/links';

const Layout = styled.section`
  padding: 24px 0 0;
  width: 100%;
  max-width: 1440px;
    margin: 0 auto;
  justify-content-center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 32px 0;
    width: 100%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 40px 0;
    width: 100%;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 16px auto;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 24px 24px 24px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 18px;
    line-height: 24px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    padding: 8px 0 0 0;
  }
`;

const StartButton = styled(LinkButton)`
  margin: 16px auto;
  display: block;
  position: relative;
  width: 100%;
  max-width: 300px;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 180px;
    display: block;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 248px;
    display: none;
  }

  &:hover {
    background-color: #E5A619;
  }
`;

const Previews: FC = () => {
  return (
    <Layout>
      <Heading>eCard previews</Heading>
      <SubTitle>Click through the cards Diana received for her Birthday</SubTitle>
      <ReviewsSlider />
      <StartButton href={CREATE_CARD_LINK}>Start now</StartButton>
    </Layout>
  );
};

export default Previews;
