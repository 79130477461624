import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    -o-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
`;

const WordContainer = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: left;
  vertical-align: top;
  width: 127px;
`;

const VisibleWord = styled.span`
  animation: ${slideIn} .6s;
  display: inline-block;
  left: 0;
  opacity: 1;
  top: 0.34em;
  white-space: nowrap;
`;

const HiddenWord = styled.span`
  animation: ${slideOut} .6s;
  display: inline-block;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0.55em;
  white-space: nowrap;
`;

interface Props {
  color: string;
  rotationSpeed: number;
  words: string[];
}
const WordSlider: FC<Props> = ({ color, rotationSpeed, words })=> {
  const [visibleIndex, setVisibleIndex] = useState<number>(0);

  useEffect(()=> {
    const interval = setInterval(()=> {
      setVisibleIndex((currentIndex)=> (currentIndex===words.length-1) ? 0 : currentIndex+1);
    }, rotationSpeed);
    return ()=> clearInterval(interval);
  }, []);

  return (
    <WordContainer color={color}>
      {words.map((word, index)=>
        (index===visibleIndex) ?
          <VisibleWord key={index}>{word}</VisibleWord> :
          <HiddenWord key={index}>{word}</HiddenWord>
      )}
    </WordContainer>
  );
}

export default WordSlider;