import React, { useRef, useState } from 'react';

import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import './eCardPreviewsStyles.css';
import { EffectCoverflow, Navigation } from 'swiper';

import 'swiper/css/navigation';

import preview03 from '../../images/ecards/preview/preview01.png';
import preview01 from '../../images/ecards/preview/preview02.png';
import preview02 from '../../images/ecards/preview/preview03.png';

const previewImages = [preview03, preview01, preview02];

export default function ReviewsSlider() {
  return (
    <>
      <Swiper
        effect={'coverflow'}
        navigation
        grabCursor={false}
        centeredSlides={true}
        slidesPerView={2.5}
        spaceBetween={20}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          scale: 1,
          slideShadows: false,
        }}
        breakpoints={{
          1224: {
            spaceBetween: 50,
          },
          768: {
            spaceBetween: 60,
          },
          460: {
            spaceBetween: 30,
          },
        }}
        modules={[EffectCoverflow, Navigation]}
        className='previewSlider'
      >
        <FadedContainerLeft></FadedContainerLeft>
        {previewImages.map((image) => (
          <SwiperSlide>
            <LargeImg src={image} />
          </SwiperSlide>
        ))}
        <FadedContainerRight></FadedContainerRight>
      </Swiper>
    </>
  );
}

const LargeImg = styled.img`
  max-width: 600px;
`;

const FadedContainerLeft = styled.div`
  position: absolute;
  width: 10%;
  top: 10%;
  left: -10px;
  background-image: linear-gradient(
    to right,
    #fffdf3 25%,
    rgba(255, 253, 243, 0.6),
    rgba(255, 253, 243, 0)
  );
  z-index: 0;
  height: 100%;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 10%;
    z-index: 2;
  }
`;

const FadedContainerRight = styled.div`
  position: absolute;
  width: 10%;
  top: 10%;
  right: -10px;
  background-image: linear-gradient(
    to left,
    #fffdf3 25%,
    rgba(255, 253, 243, 0.6),
    rgba(255, 253, 243, 0)
  );
  z-index: 0;
  height: 100%;
  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 10%;
    z-index: 2;
  }
`;
