
const headingTexts: string[] = [
  'birthday',
  'farewell',
  'thank you',
  'get well',
  'anniversary',
  'any occasion'
];

export default headingTexts;