import { Gift } from '../../common/types';

import pnThumb01 from '../../../images/ecards/private-notes/pn-thumb-01.png';
import pnThumb02 from '../../../images/ecards/private-notes/pn-thumb-02.png';
import pnThumb03 from '../../../images/ecards/private-notes/pn-thumb-03.png';
import pnThumb04 from '../../../images/ecards/private-notes/pn-thumb-04.png';
import pnThumb05 from '../../../images/ecards/private-notes/pn-thumb-05.png';
import pnThumb06 from '../../../images/ecards/private-notes/pn-thumb-06.png';

const gifts: Gift[] = [
  {
    image: pnThumb01,
    text: '',
    alt: 'Group cards',
  },
  {
    image: pnThumb02,
    text: '',
    alt: 'Group cards',
  },
  {
    image: pnThumb03,
    text: '',
    alt: 'Group cards',
  },
  {
    image: pnThumb04,
    text: '',
    alt: 'Group cards',
  },
  {
    image: pnThumb05,
    text: '',
    alt: 'Group cards',
  },
  {
    image: pnThumb06,
    text: '',
    alt: 'Group cards',
  },
];

export default gifts;
