import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import LinkButton from '../../components/LinkButton';
import OrbContainer from '../../components/OrbContainer';
import WordSlider from './components/WordSlider';
import useFormFactors from '../../helpers/useFormFactors';
import useMeaningfulGroupGifts from '../common/hooks/useMeaningfullGroupGifts';
import { CREATE_CARD_LINK, EXPLORE_GIFTS, MAIN_PAGE_LINK } from '../../constants/links';
import { Review } from '../common/types';
import blob from '../../images/blobs/blob.png';
import futureCelebrating from '../../images/future-of-celebrating.png';
import futurePhone from '../../images/ecards/header/iPhone.png';

const PreviewLink = 'https://link.illumenotes.com/Bzj6qMQUAddixFtz6';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    padding: 32px 0;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 40px 0;
  }
`;

const Section = styled.div<{ zIndex?: number; maxWidth?: number }>`
  position: relative;
  width: 100%;
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`};
  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 50%;
  }
`;

const Body = styled.div`
  padding: 24px 5px;
  width: 100%;
  z-index: 2;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0 0 0 24px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 48px 0 0 64px;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 16px 0;
  text-align: left;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    margin: 0 auto;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    max-width: 410px;
  }
`;

const HeadingLineNoWrap = styled.span`
  white-space: nowrap;
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 16px 0;
  text-align: left;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0 0 0;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    margin: 16px 0 0 0;
    max-width: 410px;
  }
`;
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 328px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 456px;
  }
`;

const GroupGiftLink = styled(LinkButton)`
  margin: 0;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 32px 0 0 0;
    max-width: 184px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 32px 0 0 0;
    max-width: 240px;
  }
  &:hover {
    background-color: #e5a619;
  }
`;

const ExploreGiftsLink = styled(LinkButton)`
  background: ${(props) => props.theme.colors.lightPeach3};
  color: #000;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 32px 0 0 0;
    max-width: 136px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 32px 0 0 0;
    max-width: 176px;
  }
`;

const Ticker = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 18px;
  margin: 8px 0 0 0;
  width: 100%;
  strong {
    padding: 0 4px 0 0;
  }
  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 184px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 240px;
  }
`;

const Blob = styled.img`
  height: 905px;
  left: -245px;
  position: absolute;
  top: -144px;
  width: 849px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 480px;
    left: -110px;
    position: absolute;
    top: -175px;
    width: 480px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 800px;
    left: -110px;
    position: absolute;
    top: -175px;
    width: 800px;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 485px;
  max-height: 485px;
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  position: relative;
  margin: 0 auto;
  @media ${(props) => props.theme.breakpoints.tabletP} {
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 48px 64px 0 0;
    margin: 0 0 0 auto;
  }
`;

const CelebratingImage = styled.img`
  width: 90%;
  margin-left: 10%;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin-top: -28px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: -28px;
  }
`;

const PhoneImage = styled.img`
  bottom: 0;
  position: absolute;
  height: 80%;
  left: 15px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    bottom: -22px;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    bottom: -22px;
  }
`;

interface Props {
  headingTexts: string[];
  reviews: Review[];
}
const MakeSomeonesOccasionTogether: FC<Props> = ({ headingTexts, reviews }) => {
  const [blobElement, setBlobElement] = useState<HTMLImageElement>();
  const notesRef = useRef<HTMLElement>(null);

  const formFactors = useFormFactors();

  const { numberOfNotes } = useMeaningfulGroupGifts({ notesRef });

  const blobRef = useCallback((element: HTMLImageElement) => {
    setBlobElement(element);
  }, []);

  const orbMovementMultiplier = useMemo(
    () => (formFactors.mobile ? 10 : formFactors.tablet ? 15 : 20),
    []
  );

  return (
    <Layout>
      <OrbContainer
        childElement={blobElement}
        multiplier={orbMovementMultiplier}
        style={{ zIndex: 1 }}
      >
        <Blob alt='' src={blob} ref={blobRef} />
      </OrbContainer>
      <Section style={{ zIndex: 1 }}>
        <Body>
          <Heading>
            <HeadingLineNoWrap>Make someone's</HeadingLineNoWrap>
            <br />
            <WordSlider color={theme.colors.orange} rotationSpeed={3000} words={headingTexts} />,
            <br />together.
          </Heading>
          <Paragraph>
            Send a digital group card with personalized messages to celebrate an individual for an
            occasion or just because.
          </Paragraph>
          <Actions>
            <GroupGiftLink href={MAIN_PAGE_LINK}>Create a free card</GroupGiftLink>
            <ExploreGiftsLink href={PreviewLink}>Preview a card</ExploreGiftsLink>
          </Actions>
          <Ticker>
            <strong>{numberOfNotes}</strong> notes sent with love &#10084;&#65039;
          </Ticker>
        </Body>
      </Section>
      <Section zIndex={2} maxWidth={800}>
        <ImageWrapper>
          <CelebratingImage alt='Group celebration' src={futureCelebrating} />
          <PhoneImage alt='Group card' src={futurePhone} />
        </ImageWrapper>
      </Section>
    </Layout>
  );
};

export default MakeSomeonesOccasionTogether;
