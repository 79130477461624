import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import OrbContainer from '../../components/OrbContainer';

import LinkButton from '../../components/LinkButton';
import themepackimg from '../../images/ecards/theme-packs/theme-packs.png';
import themepackimgMobile from '../../images/ecards/theme-packs/theme-pack-mobile.png';

import { MAIN_PAGE_LINK } from '../../constants/links';
import useFormFactors from '../../helpers/useFormFactors';
import blob from '../../images/blobs/blob.png';

const Layout = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 22px 82px 22px;
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  z-index: 2;
  justify-content-center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    padding: 0 56px 82px 56px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 0 86px 82px 86px;
  }
`;

const Section = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 50%;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.dirtOrange};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 0 0 16px 0;
  text-align: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 20px;
    text-align: left;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 16px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    margin: 0 auto;
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    max-width: 529px;
    text-align: left;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 26px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0 0 0;
    width: 80%;
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    max-width: 408px;
    text-align: left;
  }
`;

const DesktopActions = styled.div`
  display: flex;
  flex-direction: column;
  display: none;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 328px;
    display: flex;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 456px;
    display: flex;
  }
`;
const MobileActions = styled.div`
  display: flex;
  flex-direction: column;
  z-index:1;
  margin-top:45px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: none;
  }
`;

const CreateLink = styled(LinkButton)`
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 8px 0;
    max-width: 180px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    align-self: center;
    margin: 24px 0;
    max-width: 248px;
  }

  &:hover {
    background-color: #e5a619;
  }
`;

const ThemepackImage = styled.img`
  align-self: center;
  height: auto;
  width: 100%;
  display: none;
  box-shadow: 8px 8px #f5f0e1;
  border-radius: 8px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    align-self: flex-start;
    margin: 0;
    display: inline-block;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: auto;
    margin: 0;
    width: 100%;
    display: inline-block;
  }
`;

const Blob = styled.img`
  height: 905px;
  right: -245px;
  position: absolute;
  top: 0;
  width: 849px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 480px;
    right: -110px;
    position: absolute;
    top: 0;
    width: 480px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 800px;
    right: -110px;
    position: absolute;
    top: 0;
    width: 800px;
  }
`;

const ThemepackImageMobile = styled.img`
  align-self: center;
  height: auto;
  width: 100%;
  max-width: 214px;
  display: inline-block;
  margin-top: 26px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: none;
  }
`;

const ThemePack: FC = () => {
  const [blobElement, setBlobElement] = useState<HTMLImageElement>();
  const blobRef = useCallback((element: HTMLImageElement) => {
    setBlobElement(element);
  }, []);

  const formFactors = useFormFactors();

  const orbMovementMultiplier = useMemo(
    () => (formFactors.mobile ? 10 : formFactors.tablet ? 15 : 20),
    []
  );

  return (
    <Layout>
      <OrbContainer
        childElement={blobElement}
        multiplier={orbMovementMultiplier}
        style={{ zIndex: 1 }}
      >
        <Blob alt='' src={blob} ref={blobRef} />
      </OrbContainer>
      <Section style={{ zIndex: 1 }}>
        <Title>Theme Packs</Title>
        <Heading>Custom themes for any occasion</Heading>
        <Paragraph>
          Whether it’s life of the party, retro vibes, or classic, we guarantee there is a theme
          perfect for your occasion.
        </Paragraph>
        <DesktopActions>
          <CreateLink href={MAIN_PAGE_LINK}>Create your card</CreateLink>
        </DesktopActions>
      </Section>
      <Section style={{ zIndex: 1 }}>
        <ThemepackImage alt='Group card media' src={themepackimg} width={584} height={378} />
        <ThemepackImageMobile alt='Group card media' src={themepackimgMobile} />
      </Section>
      <MobileActions>
        <CreateLink href={MAIN_PAGE_LINK}>Create your card</CreateLink>
      </MobileActions>
    </Layout>
  );
};

export default ThemePack;
