
import { Review } from '../../common/types';

import preview1Large from '../../../images/ecards/preview/preview01.png';
import preview1Small from '../../../images/ecards/preview/preview01.png';
import preview1User from '../../../images/ecards/preview/preview01.png';
import preview2Large from '../../../images/ecards/preview/preview02.png';
import preview2Small from '../../../images/ecards/preview/preview02.png';
import preview2User from '../../../images/ecards/preview/preview02.png';
import preview3Large from '../../../images/ecards/preview/preview03.png';
import preview3Small from '../../../images/ecards/preview/preview03.png';
import preview3User from '../../../images/ecards/preview/preview03.png';


const data: Review[] = [{
  comment: '',
  imageLarge: preview1Large,
  imageSmall: preview1Small,
  imageUser: preview1User,
  subtitle: '',
  title: '',
}, {
  comment: '',
  imageLarge: preview2Large,
  imageSmall: preview2Small,
  imageUser: preview2User,
  subtitle: '',
  title: '',
}, {
  comment: '',
  imageLarge: preview3Large,
  imageSmall: preview3Small,
  imageUser: preview3User,
  subtitle: '',
  title: '',
}];

export default data;