import React, { FC } from 'react';
import styled from 'styled-components';
import Root from 'react-fast-marquee';

import { Gift } from '../../common/types';

const Layout = styled.section`
  align-self: center;
  margin: 0 -24px;
  padding: 2px 4px;
  width: 100vw;

  .marquee-container {
    overflow-y: hidden;
  }

  .marquee {
    gap: 0 10px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 4px 0;
  }
`;

const GiftWrapper = styled.div`
  height: 109px;
  margin: 0 10px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 166px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 166px;
  }
`;

const Image = styled.img`
  border-radius: 8px;
  height: 100%;
`;

interface GiftProps {
  image: string;
  alt: string;
}
const GiftComponent: FC<GiftProps> = ({ image, alt }) => {
  return (
    <GiftWrapper>
      <Image alt={alt} src={image} />
    </GiftWrapper>
  );
};

interface Props {
  direction: 'left' | 'right' | undefined;
  gifts: Gift[];
}
const Marquee: FC<Props> = ({ direction, gifts }) => {
  return (
    <Layout>
      <Root direction={direction} gradient={false} loop={0} speed={10}>
        {gifts.map((gift, index) => (
          <GiftComponent key={`${index}_1`} {...gift} />
        ))}
        {gifts.map((gift, index) => (
          <GiftComponent key={`${index}_2`} {...gift} />
        ))}
      </Root>
    </Layout>
  );
};

export default Marquee;
