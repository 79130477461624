import topics from '../../common/data/topics';
import footer from './footer';
import gifts from './gifts';
import headingTexts from './headingTexts';
import links from './links';
import reviews from './reviews';
import testimonials from './testimonials';

const data = {
  footer,
  gifts,
  headingTexts,
  links,
  reviews,
  testimonials,
  topics,
};

export default data;
