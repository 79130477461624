
import testimonial1Large from '../../../images/ecards/preview/preview01.png';
import testimonial1Small from '../../../images/ecards/preview/preview01.png';
import testimonial2Large from '../../../images/ecards/preview/preview02.png';
import testimonial2Small from '../../../images/ecards/preview/preview02.png';
import testimonial3Large from '../../../images/ecards/preview/preview03.png';
import testimonial3Small from '../../../images/ecards/preview/preview03.png';


const data = [{
    imageLarge: testimonial1Large,
    imageSmall: testimonial1Small,
    testimonial: '',
    user: ''
  }, {
    imageLarge: testimonial2Large,
    imageSmall: testimonial2Small,
    testimonial: '',
    user: ''
  }, {
    imageLarge: testimonial3Large,
    imageSmall: testimonial3Small,
    testimonial: '',
    user: ''
  }
];

export default data;