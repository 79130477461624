import React, { FC } from 'react';
import styled from 'styled-components';
import Marquee from './components/Marquee';
import { Gift } from '../common/types';

const Layout = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 82px 40px;
  width: 100%;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 82px 64px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 82px 64px;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 16px auto;
  text-align: center;
  max-width: 600px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 24px 24px 24px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 18px;
    line-height: 24px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    padding: 8px 0 0 0;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.dirtOrange};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 20px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 24px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 14px;
    line-height: 18px;
    margin: 24px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 14px;
    line-height: 18px;
  }
`;

interface Props {
  gifts: Gift[];
}
const PrivateNotes: FC<Props> = ({ gifts }) => {
  const marqueeOneImages = gifts.slice(0, 5);
  return (
    <Layout>
      <Title>Private Notes</Title>
      <Heading>A private message from everyone in the group</Heading>
      <SubTitle>Give a unique moment they'll never forget</SubTitle>
      <Marquee direction='left' gifts={marqueeOneImages} />
      <Paragraph>
        No limit to contributors on a card. All notes are kept private by default. Only the
        recipient will see what you write.
      </Paragraph>
    </Layout>
  );
};

export default PrivateNotes;
