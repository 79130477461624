import React, { FC } from 'react';
import styled from 'styled-components';
import spotify from '../../icons/spotify.svg';

import {
    HOWITWORKS_LINK,
    ECARDS_LINK,
    GROUPCARDS_LINK,
    OCCASIONS_LINK,
    GIFTS_LINK,
    POSTS_LINK,
    ABOUT_LINK,
    TERMS_LINK,
    PRIVACY_LINK,
} from '../../constants/links';

interface Props {
  isBlog?: boolean;
}
const Footer: FC<Props> = ({ isBlog }) => {
  const year: number = new Date().getFullYear();

  return (
    <Layout isBlog={isBlog}>
      <Links>
        <LinkContainer>
          <a href={HOWITWORKS_LINK}>How it works</a>
          <a href={ECARDS_LINK}>eCards</a>
          <a href={OCCASIONS_LINK}>Occasions</a>
          <a href={GIFTS_LINK}>Gifts</a>
          <a href={POSTS_LINK}>Blog</a>
          <a href={ABOUT_LINK}>About Us</a>
        </LinkContainer>
        <LinksContainerMobile>
            <span>
                <a href={HOWITWORKS_LINK}>How it works</a>
                <a href={ECARDS_LINK}>eCards</a>
                <a href={OCCASIONS_LINK}>Occasions</a>
            </span>
            <span>
                <a href={GIFTS_LINK}>Gifts</a>
                <a href={POSTS_LINK}>Blog</a>
                <a href={ABOUT_LINK}>About Us</a>
            </span>
        </LinksContainerMobile>
      </Links>
      <CopyrightAndNavLinks>
        <Copyright>
          © {year}. All rights reserved. Dedicated to those who shine. <span>|</span>
        </Copyright>
        <NavLinks>
          <NavLink href={TERMS_LINK}>Terms</NavLink>
          <NavLink href={PRIVACY_LINK}>Privacy</NavLink>
        </NavLinks>
      </CopyrightAndNavLinks>
    </Layout>
  );
};

export default Footer;




 const Layout = styled.section<{ isBlog?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 32px 23px 32px;
  width: 100%;
  ${(props) =>
    props.isBlog
      ? `
    position: absolute;
    background-color: ${props.theme.colors.lightPeach2};
  `
      : `max-width: 1440px;`}

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 32px;
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptopM} {
    flex-direction: row;
    padding: 40px;
    width: 100%;
  }
`;

 const Links = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 128px;
  margin: 16px 0 0 0;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.laptopM} {
    bottom: 0;
    flex-direction: row;
    height: 48px;
    position: absolute;
    width: auto;
    margin: 16px 0 24px 0;
  }
`;


const LinksContainerMobile = styled.div`

    display: flex;
    flex-direction: row;

    span {
        display: flex;
        flex-direction: column;
        margin-right: 49px;
    }

    a {
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
        font-weight: 700;
        margin-bottom: 8px;
    }

    img {
        margin: 0 4px -2px 0;
        width: 16px;
        height: 16px;
    }
    @media ${(props) => props.theme.breakpoints.tabletP} {
        display: flex;
    }

    @media ${(props) => props.theme.breakpoints.laptopM} {
        display: none;
    }
`


const LinkContainer = styled.div`
  
  display: none;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.laptopM} {
    display: flex;
    padding: 0 8px;
    margin: 4px 0;
    img {
        margin: 0 4px -2px 0;
        width: 16px;
        height: 16px;
    }
    
      a {
      white-space: nowrap;
      line-height: 24px;
      font-weight: 700;
      margin-right: 32px;
    }
  }
`;

const Copyright = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0 4px 0;

    span {
        display: none;
    }

  @media ${(props) => props.theme.breakpoints.laptopM} {
    font-size: 14px;
    line-height: 18px;

    span {
        display: initial;
        margin: 0 20px;
    }


  }
`;

const CopyrightAndNavLinks = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 8px 0 0 0;
    
  }

  @media ${(props) => props.theme.breakpoints.laptopM} {
    bottom: 0;
    flex-direction: row;
    font-size: 14px;
    height: 48px;
    justify-content: space-between;
    line-height: 18px;
    margin: 16px 0 24px 0;
    position: absolute;
    right: 48px;
  }
`;

const NavLinks = styled.div`
  display: flex;
  margin-left: -8px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin-left: -8px;
  }

  @media ${(props) => props.theme.breakpoints.laptopM} {
    margin-left: 0;
  }

`;

const NavLink = styled.a`
  line-height: 24px;
  margin: 4px 0;
  padding: 0 8px;

  @media ${(props) => props.theme.breakpoints.laptopM} {
    font-size: 14px;
    line-height: 18px;
  }
`;
