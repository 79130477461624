import React, { FC } from 'react';
import styled from 'styled-components';

import LinkButton from '../../components/LinkButton';

import ImageWall from './components/ImageWall';

import image01 from '../../images/ecards/media-collages/wall01.png';
import image02 from '../../images/ecards/media-collages/wall02.png';
import image03 from '../../images/ecards/media-collages/wall03.png';
import image04 from '../../images/ecards/media-collages/wall04.png';
import image05 from '../../images/ecards/media-collages/wall05.png';

import { CREATE_CARD_LINK, MAIN_PAGE_LINK } from '../../constants/links';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 82px 0;
  width: 100%;
  max-width: 1440px;
    margin: 0 auto;
  justify-content-center;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    padding: 142px 56px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 142px 78px;
  }
`;

const Section = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 50%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    justify-content: flex-end;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    justify-content: flex-end;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.dirtOrange};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 0 0 16px 0;
  text-align: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 20px;
    text-align: left;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 16px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    text-align: left;
    width: 85%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    max-width: 529px;
    text-align: left;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 46px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0 36px 0;
    width: 85%;
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    max-width: 408px;
    text-align: left;
    margin: 0 0 16px 0;
  }
`;

const Actions = styled.div`
  flex-direction: column;
  display: none;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 328px;
    display: flex;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 456px;
    display: flex;
  }
`;

const ActionsMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 26px;
  padding: 0 16px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: none;
  }
`;

const CreateLink = styled(LinkButton)`
  width: 100%;
  margin: 34px 0;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 8px 0;
    max-width: 180px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    align-self: center;
    margin: 24px 0;
    max-width: 248px;
  }

  &:hover {
    background-color: #E5A619;
  }
`;


const MediaCollages: FC = () => {
  return (
    <Layout>
      <Section>
        <Title>Media Collages</Title>
        <Heading>Express yourself the way you want</Heading>
        <Paragraph>
          Personalize your note with photos, videos, GIFs and more. Not sure what to write? Check
          out our inspiration library!
        </Paragraph>
        <Actions>
          <CreateLink href={MAIN_PAGE_LINK}>Get started</CreateLink>
        </Actions>
      </Section>
      <Section>
        <ImageWrapper>
          <ImageWall
            images={[
              { image: image01, alt: 'Birthday GIF' },
              { image: image02, alt: 'Birthday video' },
              { image: image03, alt: 'Birthday card' },
              { image: image04, alt: 'Birthday card' },
              { image: image05, alt: 'Birthday photo' },
            ]}
          />
        </ImageWrapper>
        <ActionsMobile>
          <CreateLink href={MAIN_PAGE_LINK}>Get started</CreateLink>
        </ActionsMobile>
      </Section>
    </Layout>
  );
};

export default MediaCollages;
